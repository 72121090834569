<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="vtd-banner solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">VTD</h1>
        <p data-caption-delay="100" class="wow fadeInRight">提供高保真的虚拟交通场景，高精度的物理级传感器模型，智慧交通流等，用于自动驾驶及ADAS系统的仿真测试与验证</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <div class="vtd-all">
      <section class="solution-intros wow fadeInRight">
        <div class="container">
          <div class="normal-title">产品介绍</div>
          <div class="all-intro">
            <p class="intro-detail">
              VTD是世界上使用广泛的开放平台，用于创建、配置和动画化仿真环境以及用于ADAS和自动驾驶汽车的培训、测试和验证的场景。它提供了用于道路网络创建、场景定义、车辆动力学、交通和声音模拟、模拟控制、图像生成、传感器感知等的模块化工具集，创建复杂驾驶场景的数字现实。
            </p>
          </div>
        </div>
      </section>

      <section class="vehicle-solution-sections wow fadeInRight">
        <div class="container">
          <div class="normal-title">功能特点</div>
          <div class="tab-container vtd-tab-container">
            <el-tabs :tab-position="isMobile ? 'top' : 'left'" :class="isMobile ? 'tab-top' : ''">
              <el-tab-pane label="传感器">
                <div class="tab-detail-container">
                  <ul class="function-list">
                    <li>简化的传感器提供对象列表，高保真的传感器提供原始数据，例如图像和点云</li>
                    <li>传感器用于道路标记检测</li>
                    <li>用于自定义传感器模型的SDK行人</li>
                    <li>行人能够在指定道路上的进行特定运动</li>
                    <li>行人能够在测试中以及系统互动时自发地在道路上进行大量的移动（例如，行人看着车辆，朝车辆走去）</li>
                  </ul>
                </div>
              </el-tab-pane>
              <el-tab-pane label="场景">
                <div class="tab-detail-container">
                  <ul class="function-list">
                    <li>可以模拟从简单的到有200多个参与者的复杂的城市情况</li>
                    <li>场景可能是基于现实世界，也可能是完全人工的</li>
                    <li>可以基于采集数据进行精确的建模天气</li>
                    <li>时间、云、能见度、降水量的变化支持参数化设置</li>
                  </ul>
                </div>
              </el-tab-pane>
              <el-tab-pane label="车辆模型">
                <p class="tab-inner-intro">从踏板车到火车和直升机的所有运输方式，都可以“按比例”建模，精度可达毫米级。</p>
              </el-tab-pane>
              <el-tab-pane label="交通模型">
                <p class="tab-inner-intro">模拟独立的、智能的单元，能够自动或按指定模式运动。</p>
              </el-tab-pane>
              <el-tab-pane label="大规模缩放">
                <div class="tab-detail-container">
                  <ul class="function-list">
                    <li>并行分析了数千个场景，来检测边缘情况</li>
                    <li>使用PROSTEP OpenPDM技术的PDM集成</li>
                    <li>自动捕获所有的数据处理过程</li>
                    <li>基于Web的配置方法能够实现快速部署</li>
                    <li>可配置支持多地点协同</li>
                  </ul>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </section>

      <div class="vehicle-solution-sections wow fadeInRight">
        <div class="product-vtd">
          <div class="container">
            <div class="normal-title">技术优势</div>
            <div class="vtd-technical-advantages vtd-system-picture">
              <el-row>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <div class="technical-advantages">
                    <ol>
                      <li style="list-style-type: '1. '">支持OpenDRIVE®、OpenCRG®和OpenSCENARIO®</li>
                      <li style="list-style-type: '2. '">通过网络接口实现高度模块化和可扩展化</li>
                      <li style="list-style-type: '3. '">适用于不同应用领域（MIL、SIL、DIL、VIL、HIL）</li>
                      <li style="list-style-type: '4. '">精确的传感器模型（基于目标信息列表和基于物理的传感器模型）可通过SDK自定义</li>
                      <li style="list-style-type: '5. '">高质量图像实时渲染（PBR技术），支持用户自定义</li>
                      <li style="list-style-type: '6. '">包含各种3D模型库和特定国家/地区的标志/信号数据库</li>
                    </ol>
                  </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                  <div class="technical-advantages">
                    <ol><!--默认圆点类型，可以不写-->
                      <li style="list-style-type: '7. '">复杂交通工况仿真</li>
                      <li style="list-style-type: '8. '">通过GUI和命令行，轻松地进行实时数据监控和输入</li>
                      <li style="list-style-type: '9. '">可从在个人计算机和完整的HPC环境下运行</li>
                      <li style="list-style-type: '10. '">轻松集成其他Hexagon AB解决方案，如Adams实时精确的传感器建模的车辆动力学模型</li>
                      <li style="list-style-type: '11. '">从Hexagon的Leica Geosystems中获取数据，建立场景模型</li>
                      <li style="list-style-type: '12. '">一千种方案中的边缘案例检测和云端技术支持</li>
                    </ol>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <section class="vehicle-solution-sections wow fadeInLeft">
        <div class="container">
          <div class="normal-title">核心组件</div>
          <p class="core-components-first-line">VTD可以使用开放的标准（OpenDRIVE、OpenCRG和OpenSCENARIO）</p>
          <div class="solution-components">
            <el-row class="components-content" :gutter="40">
              <el-col class="hidden-md-and-down" :xs="24" :sm="24" :md="24" :lg="10" :xl="10" style="text-align: right">
                <img src="../../assets/images/solution-electric-components-pic-02.png" style="height: 260px;margin-top: 18px" alt="">
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="components-text">
                  <div>
                    <h1>OpenDRIVE</h1>
                    <p class="core-components-context1">OpenDRIVE是全球领先的开放格式，也是驾驶模拟应用中道路网络描述的实际标准</p>
                  </div>
                  <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 80px'">
                    <h1>OpenCRG</h1>
                    <p class="core-components-context2">OpenCRG是用于创建、管理和评估详细路面的领先的开源数据格式和工具套件</p>
                  </div>
                  <div :style="isMobile ? 'margin-top: 20px' :'margin-top: 50px'">
                    <h1>OpenSCENARIO</h1>
                    <p class="core-components-context2">OpenSCENARIO是先进的用于定义模拟道路网络中动态行为的开放格式</p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <p class="core-components-first-line" style="line-height: 30px;">VTD的Road
            Designer（ROD）是一种交互式的路网建模器，可以用作3D世界创建的基础。它由代表不同国家3D对象和纹理的大量资源库组成，可以创建完整的数据库和基本构造块（所谓的“平铺”）。</p>
        </div>
      </section>
      <div class="solution-intros wow fadeInRight">
        <div class="container">
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="14">
              <div class="normal-title">应用领域</div>
              <div class="all-intro">
                <p class="intro-detail">
                  VTD是一个在市场上已经存在20多年的软件包，它在全球的汽车、航空航天和铁路行业被广泛使用，同时也在矿业、农业和航运业中得到应用。可以用于MIL、软件在环（SIL）、硬件在环（HIL）、驾驶员在环（DIL）和车辆在环（VIL）的交通场景模拟。VTD的最新研究进展能够在云端进行大规模的方案“边缘情况”的检测。这是通过使用数以千计的并行流程分析数百万种方案来完成的，比实时仿真更快地完成数十亿次虚拟测试，提高了ADAS和AD系统的速度。</p>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
              <div class="application-picture" style="margin-top: 40px;float: right">
                <img src="../../assets/images/product-vtd-application.png" alt="">
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="vtd-components-background wow fadeInLeft">
        <div class="product-vtd container">
          <el-row>
            <el-col :span="24">
              <div class="headquarters">系统图片</div>
            </el-col>
          </el-row>
          <el-row style="margin-top: 40px;padding-bottom: 60px">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div class="vtd-system-picture" style="text-align: center">
                <img src="../../assets/images/vtd-system-image1.jpeg" alt="">
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div class="vtd-system-picture" style="text-align: center">
                <img src="../../assets/images/vtd-system-image2.jpeg" alt="">
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <bzInfo style="margin-top: 0"/>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from "@/components/MainNav.vue"
import mainFooter from "@/components/MainFooter.vue"
import "@/style/vtd.less"
import "@/style/vector/cast.less"
import "@/style/solution.less"
import bzInfo from "@/components/BzInfo.vue"
import 'element-ui/lib/theme-chalk/display.css';
export default {
  name: "VTD",
  components: {
    mainFooter,
    mainNav,
    bzInfo
  },
  data() {
    return {
      activeIndex: '3-2',
      functionScroll: 0,
      chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      isMobile: false,
    }
  },
  mounted() {
    document.title = "VTD - 产品中心 - 东信创智"
    new this.$wow.WOW().init();
    this.isMobile = document.documentElement.clientWidth < 1200
    window.onresize = () => {
      return (() => {
        this.isMobile = document.documentElement.clientWidth < 1200;
      })();
    }
  },
  methods: {
    linkUrl(url) {
      window.open(url, '_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>
